import React from "react";
import "react-slideshow-image/dist/styles.css";
import "./Slider.css";
import { Slide } from "react-slideshow-image";
import w1 from "../../Images/wallpaper.jpg";
import w2 from "../../Images/rr.jpg";
import sa from "../../Images/saha.jpeg";
import ht from "../../Images/hotel.jpeg";

const slideImages = [w1, w2];

const Slideshow = () => {
  return (
    <div className="sMian" style={{ height: "70vh" }}>
      {/* <Slide easing="ease" style={{ padding: 0, margin: 0 }}>
        <div className="each-slide" style={{ margin: 0, padding: 0 }}>
          <div style={{ backgroundImage: `url(${slideImages[0]})` }}></div>
        </div>
        <div className="each-slide">
          <div style={{ backgroundImage: `url(${slideImages[1]})` }}></div>
        </div>
      </Slide> */}
    </div>
  );
};

export default Slideshow;
